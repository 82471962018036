import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { LinkType, Productcard } from '../components/shared'

import {
  desktopVw,
  desktopBreakpoint,
  mobileVw,
  colors,
  fonts,
} from '../styles'
import { useLang, useLocale } from '../hooks'

const Results = ({ location }) => {

  const lang = useLang();

  const data = useStaticQuery(graphql`
    query {
      allContentfulPageRecipe {
        nodes {
          node_locale
          thumbnailImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
            description
          }
          title
          slug
          relatedProducts {
            title
          }
        }
      }
      allContentfulProduct {
        nodes {
          node_locale
          thumbnailImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          title
          slug
        }
      }
    }
  `)

  const locale = useLocale()

  const allRecipes = []
  data.allContentfulPageRecipe.nodes.map((recipe) => {
    if (recipe.node_locale === locale && recipe.title && recipe.slug) {
      allRecipes.push(recipe)
    }
  })

  const allProducts = []

  data.allContentfulProduct.nodes.map((product) => {
    if (product.title && product.slug) {
      allProducts.push(product)
    }
  })

  const specificProducts = []

  allProducts.map((product) => {
    if (product.title && product.slug) {
      specificProducts.push(product)
    }
  })

  const matchingRecipes = []
  const matchingProducts = []

  const getRecipes = () => {
    allRecipes.map(
      ({ relatedProducts }, i) =>
        relatedProducts &&
        relatedProducts.map(({ title }) =>
          title
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(`${search.toLowerCase()}`) ||
            allRecipes[i].title
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(`${search.toLowerCase()}`)
            ? matchingRecipes.push(allRecipes[i])
            : null,
        ),
    )
  }

  const getProducts = () => {
    specificProducts.map((product) =>
      product.title
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(`${search.toLowerCase()}`)
        ? matchingProducts.push(product)
        : null,
    )
  }

  const search = location.search.replace('?', '').split('%20').join(' ')

  return (
    <Wrapper>
      {getRecipes()}
      {getProducts()}
      <ProductGallery>
        {matchingProducts.map((product, i) => (
          <ProductWrapper key={i}>
            <Productcard product={product} />
          </ProductWrapper>
        ))}
      </ProductGallery>
      {search && matchingRecipes.length == 0 ? (
        <Span>Désolé votre recherche n'a renvoyé aucun résultat.</Span>
      ) : null}
      <Recipes>
        {search && matchingRecipes
          ? Array.from(new Set(matchingRecipes)).map(
            ({ title, thumbnailImage, slug }, i) => (
              <RecipeWrapper to={`/${lang}${slug}`} key={i}>
                <StyledImage
                  fluid={thumbnailImage && thumbnailImage.fluid}
                  alt={thumbnailImage && thumbnailImage.title}
                />
                <RecipeTitle>{title}</RecipeTitle>
              </RecipeWrapper>
            ),
          )
          : null}
      </Recipes>
    </Wrapper>
  )
}

export default Results

const Wrapper = styled.div`
  margin: 0;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0;
    padding: ${desktopVw(30)};
  }
`

const RecipeWrapper = styled(LinkType)`
  margin: ${mobileVw(10)} 0 ${mobileVw(30)};
  display: block;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0;
  }
`

const Recipes = styled.div`
  display: block;

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: ${desktopVw(80)} ${desktopVw(30)};
  }
`

const Span = styled.h3`
  padding: ${mobileVw(16)} 0 0;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(26)};
    padding: 0;
  }
`

const RecipeTitle = styled.h2`
  font-size: ${mobileVw(18)};
  line-height: ${mobileVw(18)};
  font-family: ${fonts.splandor};
  margin: ${mobileVw(18)} 0 ${mobileVw(8)} 0;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(24)};
    line-height: ${desktopVw(24)};
    margin: ${desktopVw(22)} 0 ${desktopVw(10)} 0;
  }
`

const StyledImage = styled(Image)`
  height: ${mobileVw(264)};

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVw(280)};
  }
`

const ProductWrapper = styled.div`
  margin-bottom: ${mobileVw(8)};
  width: ${mobileVw(136)};

  button {
    display: none;
  }

  p {
    margin-bottom: 0;
  }

  padding-left: ${mobileVw(7)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(255)};
    margin-left: ${desktopVw(19)};

    .imageContainer {
      height: ${desktopVw(220)};
    }
  }
`

const ProductGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`

const ButtonWrapper = styled.div`
  padding: 0 ${mobileVw(16)};
  margin-bottom: ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`
